.box-wrapper {
    background: radial-gradient(closest-side, rgba(16, 12, 28, .9) 40%, rgba(255, 255, 255, 0.07) 90%);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    width: 90%;
    height: 80vh;
    display: flex;
    padding: 10px;
    box-shadow: -2px 0px 5px 1px rgba(5, 234, 255, .7);
    margin: 0px;
  }

h1 {
  color: white;
}
