/* Shared Properties */
.vintage-textbox,
.philosophy-element {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vintage-textbox {
  background: #c0c0c0;
  position: absolute;
  padding: 1px;
  box-shadow: inset 1px 1px #dfdfdf, inset -1px -1px gray;
  box-sizing: border-box;
}

div.philosophy-title {
  position: absolute;
  top: 0px;
  left: 1px;
  width: 99%;
  background: #676767;
  border: 2px solid #c0c0c0;
  color: #fff;
  box-shadow: inset 1px 1px #dfdfdf, inset -1px -1px gray;
  box-sizing: border-box;
}

div.textbox-body {
  background-color: white;
  text-align: left;
  position: absolute;
  top: 41.5px;
  padding: 5px;
  display: inline-block;
  overflow-y: scroll;
}

div.textbox-body p {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 0px 10px;
}

.philosophy-title > button {
  position: absolute;
  right: 5px;
  top: 4px;
  height: 1.5em;
  width: 1.8em;
  font-family: verdana;
  padding: 0;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  color: #808080;
  box-shadow: inset 1px 1px #dfdfdf, inset -1px -1px gray;
  -webkit-appearance: none !important;
}

.ok-button {
  position: absolute;
  bottom: 5px;
  width: 100px;
  font-family: "MS Sans Serif";
  font-size: 16px;
  background: #c0c0c0;
  box-shadow: inset 1px 1px #dfdfdf, inset -1px -1px gray;
  margin: 20px auto 3px;
  display: block;
}

.ok-emoji {
  height: 24px;
  position: relative;
  top: 2px;
}

.textbox-body::-webkit-scrollbar {
  position: absolute;
  width: 20px;
}

.textbox-body::-webkit-scrollbar:hover {
  background-color: #c0c0c0;
}

.textbox-body::-webkit-scrollbar-track {
  background-color: #e9e9e9;
}

.textbox-body::-webkit-scrollbar-thumb {
  background-color: #d4d4d4;
  box-shadow: inset 1px 1px #dfdfdf, inset -1px -1px gray;
  background-clip: content-box;
  border-radius: 0;
}

/* ----------------------MOBILE----------------------------*/
@media (max-width: 767px) {
  .vintage-textbox {
    width: 85vw !important;
    height: 85vw !important;
  }

  div.philosophy-title {
    height: 36px;
    width: 99.5%;
  }

  p.philosophy-title {
    margin: 10px 0 0 0;
    font-size: 0.75em;
    position: absolute;
    left: 1em;
  }

  div.textbox-body {
    height: calc(100% - 100px);
    width: calc(100% - 20px);
    overflow-y: scroll;
    font-size: max(1em, 2.5vw) !important;
  }
}

/* ----------------------DESKTOP----------------------------*/
@media (min-width: 768px) {
  .philosophy-element,
  .vintage-textbox {
    width: max(290px, 22vw);
    height: max(290px, 22vw);
    box-sizing: border-box;
  }

  div.philosophy-title {
    width: 99.5%;
    top: 0px;
    height: 36px;
    box-sizing: border-box;
  }

  p.philosophy-title {
    margin: 10px 0 0 0;
    font-size: 0.75em;
    position: absolute;
    top: 2px;
    left: 1em;
  }

  div.textbox-body {
    height: calc(100% - 100px);
    width: calc(100% - 20px);
    overflow-y: scroll;
  }
}
