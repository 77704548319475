:root {
  --fuschia: rgb(206, 4, 162);
  --light-blue: #2B8FF7;
  --yellow: #F7D824;
  --violet: #9672FF;
  --aqua: #05EAFF;
  --aqua-transparent: rgba(5, 234, 255, .5);
  --dark-solid: #120D1B;
  --dark-transparent: rgba(47,35,66,.7); 
  --fuschia-semi-transparent: rgba(206, 4, 162, .8);
  --fuschia-transparent: rgba(206, 4, 162, .3);
  --crystal-ball: "\01F52E";
  --handshake: "\1F91D";
  --wave: "\1F44B";
  --hamburger: "\1F354";
  --radial-gradient: radial-gradient(closest-side, rgba(16, 12, 28, .9) 40%, rgba(255,255,255,0.07) 90%);
  --box-shadow-blue-bottom: -2px 0px 5px 1px rgba(5, 234, 255, .7);


}

#root {
  width: 100%;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
}

.play-font {font-family: 'Press Start 2P', cursive;}

.trispace-font {font-family: 'Trispace', sans-serif;}

h1 {
  font-size: 2.25em;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 1.75em;
}
h4 {
  font-size: 1.25em;
}
h5 {
  font-size: 1em;
}
h6 {
  font-size: .83em;
}

p {
  font-size: 1em;
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  background-image: url('../public/background-full.svg');
  background-attachment: fixed;
  background-position: top right;
  background-size: cover;
  background-repeat: repeat-y;
  z-index: -1;
}

#background {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background-repeat: repeat-y;
  z-index: -1;
}
