.section-state-container {
    display: none;
}

.card-link {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* -------------ACCORDION CARD DESKTOP----------------*/
@media (min-width: 768px) {
    .card-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1px;
        width: 225px;
        height: 265px;
        background-image: url("../assets/card-bg.png");
        /* background: radial-gradient(
            closest-side,
            rgba(16, 12, 28, 0.9) 40%,
            rgba(255, 255, 255, 0.07) 90%
          );
        box-shadow: 0px 3px 5px 1px rgba(5, 234, 255, 0.7); */
        background-repeat: no-repeat;
        border-radius: 20px;

      }
    
    
    .card-content {
        position: relative;
        top: -5px;
        left: 2px;
        width: 190px;
        height: auto;
        padding-bottom: 10px;
    }
    
    .thumbnail-photo {
        height: 190px;
        width: 190px;
        position: absolute;
        top: -110px;
        left: -2px;
        border: 3px solid;
    }
    
    .username {
        height: 40px;
        width: 190px;
        position: absolute;
        top: 100px;
        text-align: center;
        font-size: .75em;
        width: 184px;
        margin: 0;
        overflow-wrap: break-word;
    }

    .client-name {
        color: var(--fuschia);
    }

    .client-thumbnail {
        border: 3px solid var(--fuschia);
    }
    }
    
    /* -------------ACCORDION CARD MOBILE----------------*/
    
    @media (max-width: 767px) {
    
        .card-container {
            background-size: cover;
            box-sizing: border-box;
            width: 150px;
            height: 200px;
            display: flex;
            flex-direction: column;
            background-image: url("../assets/card-bg-sm.png");
            background-size: 150px;
            background-repeat: no-repeat;
            
        }

        
    
        .thumbnail-photo {
            position: relative;
            top: 12px;
            left: 14px;
            height: 120px;
            width: 120px;
            object-fit: cover;
            border-bottom: 0px solid !important;
            margin-bottom: 20px;
            border-radius: 7px;
        }
    
        h6.username { 
            width: 130px;
            font-size: max(10px, 0.7em);
            text-align: left;
            overflow-wrap: break-word;
            margin: 10px;
            padding: 0;
            text-align: center;
            line-height: 1.5em;
        }
    
    
    } 
    