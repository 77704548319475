@media (min-width: 768px) {

  .desktop-home-page-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50vh;
    gap: 6vh;
  }
  
  .track-container {
    position: absolute;
    right: 0;
    margin-top: 5vw;
    width: 50vw;
    padding-bottom: 20vw;
  }
    
  .blue-track {
    position: absolute;
    right: 0%;
    width: 50vw;
  }
  
  .yellow-track {
    position: absolute;
    top: 10%;
    right: 0;
    width: 35vw;
  }
  
  /* gap between the list items */
  ul.home-menu-desktop {
    width: max-content;
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: .6vw;
    position: absolute;
    top: 28%;
    left: 40%;
  }
  
  /* gap between triangle and label */
  .home-menu-desktop-item a{
    width: max-content;
    display: flex;
    gap: 1em;
    align-items: center;
    justify-content: center;
    color: var(--yellow);
  }
  
  
    h5.home-menu-desktop-label {
      margin: 0;
      font-size: max(14px, 1.5vw);
      font-weight: 300;
  
    }
  
    .triangle-icon {
      color: var(--yellow);
    }
  
    .home-menu-desktop-item a:hover{
      background-color: var(--fuschia);
    }
  
    
    .philosophy-container {
      position: absolute;
      width: max(290px, 22vw);
      height: max(290px, 22vw);
      top: 27vw;
      left: 12vw;
    }
  

}
  /*---------------------------FINE TUNING-------------------------------*/
  @media (min-width: 768px) and (max-width: 991px) {

    ul.home-menu-desktop {
      top: 23%;
      left: 38%;
      gap: .3vw;
    }

    .home-menu-desktop-item a{
      gap: .7em;
    }

    h5.home-menu-desktop-label, .triangle-icon {
      font-size: .83em;
    }

        
    .philosophy-container {
      position: absolute;
      width: max(290px, 22vw);
      height: max(290px, 22vw);
      top: 27vw;
      left: 25vw;
    }
}