ul.navigation-links {
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.nav-link {
    height: max-content;
    display: flex;
    align-items: center;
    gap: 1em;
}

.triangle-icon-nav {
    height: max(1em, 1vw);
}

.triangle-icon-nav, .link-label {
    color: var(--aqua);
}

.active > .nav-link > h5,
.nav-link > h5:hover {
    color: var(--yellow);
}

.inactive > .nav-link > .triangle-icon-nav {
    visibility: hidden;;
}

@media (max-width: 767px) {
    .nav-link > h5,
    .triangle-icon-nav {
        font-size: max(1em, 3vw);
        height: max(1em, 3vw);
    }
}
