.header-container {
    height: 20vw;
    width: 100vw;
    display: flex;
}

.hamburger-icon, .hamburger-icon-img {
    height: 3.5em;
    width: 3.5em;
    position: fixed;
    right: 5vw;
    top: 5vw;
    z-index: 999;
}

#menu-toggle {
    display: none;
}

.logo-link {
    display: block;
    height: 20vw;
    width: max-content;
}

.logo-link > .mobileLogo {
    width: min(20em, 50vw);
    max-height: 20vw;
    position: absolute;
    top: 4vw;
    left: 4vw;
}