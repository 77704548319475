@media (max-width: 767px) {
  /* Hamburger Menu Container */
.hamburger-menu-container {
  width: 100vw;
  height: 88vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 12vh;
  z-index: 999;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s ease-in-out;
}

.hamburger-menu-container.open {
  transform: translateY(0%);
  opacity: 1;
}

.hamburger-menu-container.closed {
  transform: translateY(100%);
  opacity: 0;
}

/* Dark Overlay */
.dark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 990;
  background-color: rgba(13, 9, 24, 0.6); /* Updated initial transparent background color */
  /* backdrop-filter: blur(10px); */
  opacity: 0; /* Initial transparency */
  transition: opacity 0.5s ease-in-out, background-color 0.5s ease-in-out, backdrop-filter 0.5s ease-in-out;
}

.dark-overlay.visible {
  opacity: 1; /* Fully opaque */
}

.dark-overlay.hidden {
  display: none;
}


/* Hamburger Menu Wrapper */
.hamburger-menu-wrapper {
  width: 98vw;
  height: 88vh;
  background-color: var(--dark-solid);
  box-shadow: 0px -1px 5px 1px rgba(5, 234, 255, .7);
  border-radius: 50px 50px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

ul.mobile-navigation-links {
  position: relative;
  top: 20vh;
  display: flex;
  flex-direction: column;
  gap: 2em;
}
}