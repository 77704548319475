.nav-container {
    position: fixed;
    width: 40vw;
    aspect-ratio: 8/10;
    max-height: 100vh;
    display: grid;
    grid-template-columns: 1fr .5fr 1fr;
    grid-template-rows: 1fr 1fr .3fr .3fr 1fr;
    grid-template-areas:
      "logo  . blank"
      "navigation-menu . blank"
      ".  . dao-handshake"
      "pink-track pink-track pink-track"
      "contact-info-container . .";

    gap: 3em 0;
    height: 60vh;
  }

  .nav-container-home {
    position: relative;
  }
  
  .nav-container > * {
    width: 90%;
    height: 100%;
  }
  
  
  /*--------------------------LOGO---------------------------*/
  .desktop-logo {
    width: 40vw;
    margin: 2vw 5vw;
    grid-area: logo;
    display: flex;
    align-items: center;  
    gap: 1vw;
    /* position: relative;
    left: 5vw;
    top: 3vw; */
  }
  
  .desktop-logo.hidden > .desktopLogoText {
    visibility: hidden;
  }

  .desktopLogo {
    width: 10vw;
  }

  .desktopLogoText {
    width: 30vw;
  }
  /*--------------------------PINK TRACK---------------------------*/
  
  .pink-track {
    grid-area: pink-track;
  }
  
  #pink-track {
    width: 100%;
  }

  .pink-track.hidden {
    visibility: hidden;;
  }
  
  .contact-info-container {
    grid-area: contact-info-container;
    width: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  
  /*--------------------------NAVIGATION MENU---------------------------*/
  .navigation-menu {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    grid-area: navigation-menu;
    padding-left: 0;
  }
  
  .navigation-menu > .navigation-links {
    width: 100%;
    gap: 1em;
    font-size: max(1em, 1.2vw);
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
  }
  
  /* gap between triangle and label */ 
  li.nav-links {
    display: flex;
    align-items: center;
    gap: 1em;
    width: max-content;
    font-size: max(1em, 1vw);
  }
  
  #nav-triangle, li.inactive img{
    height: 1em;
  }
  
  .nav-links a{
    text-decoration: none;
    color: #05EAFF;
  }
  
  .nav-links.active a, .nav-links.active a:hover {
    color: var(--yellow); 
    text-decoration: none;
  }
  
  li.inactive img {
    visibility: hidden;
  }
  
  
  /*--------------------------CONTACT INFO---------------------------*/
  .contact-info-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  .contact-info-container.hidden {
    visibility: hidden;;
  }
  
  /* gap between emoji and text */
  .contact-info-desktop {
    display: flex;
    align-items: center;
    gap: 1em;
  }
  
  .contact-info-desktop h5, 
  .contact-info-desktop .emoji-bullet {
    color: var(--yellow);
    font-size: max(1em, 1.2vw)
  }
  
  
  .blank {
    width: 10vw;
  }
  
  
    /*--------------------------FINE TUNING---------------------------*/

.contact-info-container {
    padding-left: 4vw;
}

/* @media (min-width: 768px) and (max-width: 1080px) {

  .navigation-menu, .contact-info-container {
    padding-left: 0vw;
  }

} */

  
@media (min-width: 768px) and (max-width: 1080px) {
  
  .nav-link h5{
    font-size: .83em;
  }

  .navigation-menu {
    padding-left: 1vw;
  }

  .navigation-links {
    padding-left: 1vw;
  }
}

@media (min-width: 900px) and (max-width: 1080px) {
  .navigation-links {
    padding-left: 2vw;
  }
}
