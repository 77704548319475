ul.mobile-home-menu {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

a.mobile-home-link {
    text-decoration: none;
    color: var(--aqua);
    display: flex;
    gap: 2em;
    display: flex;
    align-items: center;
}

.mobile-home-link > h4, 
.emoji-bullet {
    font-weight: 300;
    font-size: max(1em, 3.5vw);
}
 
/*--------------------- FINE TUNING ---------------------------*/
@media (max-width: 400px) {
    .right-chevron {
        display: none;
    }

    ul.mobile-home-menu {
        padding: 0;
    }
}