.center-justify {
 justify-content: center;
}

.consulting-posts-container {
    width: 90%;
    max-height: 50vh;
    background: radial-gradient(closest-side, rgba(16, 12, 28, .9) 40%, rgba(255, 255, 255, 0.07) 90%);
    backdrop-filter: blur(10px);
    padding: 2em 1em;
    margin: 2em 0;
    box-shadow: 0px 2px 5px 1px rgba(5, 234, 255, .7);
    border-radius: 10px;
}

.consulting-posts-wrapper {
    max-height: 40vh;
    overflow-y: scroll;
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.consulting-posts-wrapper::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, .3);
    width: 5px;
}

.consulting-posts-wrapper::-webkit-scrollbar-thumb {
    background-color: var(--aqua-transparent);
    border-radius: 10px;
}

.consulting-posts-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: var(--aqua);
    border-radius: 10px;
}




.consulting-post > * {
    color: var(--aqua);
}

@media (max-width: 767px) {
    .consulting-posts-container {
        width: 80%;
        height: max-content;
        padding: 2em 1em;
    }
}