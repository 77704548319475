.app-container {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}

/* .app-container > * {
  border: 2px red solid;
} */

.main-head {
  grid-area: header;
}
.side {
  grid-area: sidebar;
}
.content {
  grid-area: content;
}
.main-footer {
  grid-area: footer;
}


/* -------------APP MOBILE----------------*/

@media (max-width: 767px) {

  .app-container {
      display: grid;
      grid-template-rows: auto max-content auto;
      grid-template-areas:
      "header"
      "content"
      "footer";
  }

  .main-head {
    height: 20vw;
    width: 100vw;
  }

  .content {
      width: 100vw;
      margin-bottom: 5vh;
  }

  .home-content {
    margin-bottom: 0;
  }

  .footer {
    width: 100vw;
  }

}



/* -------------APP DESKTOP----------------*/

@media (min-width: 768px) {
  .app-container {
      min-height: 100vh;
      display: grid;
      grid-template-columns: 3fr 7fr;
      grid-template-rows:  auto max-content auto;
      grid-template-areas:
      "header header"
      "sidebar content"
      "footer footer";
  }

  .side {
    height: 100vh;
    width: 30vw;
  }

  .home-side {
    width: 50vw;
  }


  .footer {
      position: fixed;
      margin-top: 4fr;
  }  

  .content {
      width: 70vw;
      min-height: 100vh;
      padding-bottom: 20vh;
      position: absolute;
      right: 0;
  }

  .home-content {
    width: 50vw;
  }

  .app-container-home {
      width: 100%;
      height: 100vh;
      display: grid;
      grid-template-columns: 5fr 5fr;
      grid-template-rows: auto max-content auto;
      grid-template-areas:
      "header  header"
      "sidebar content"
      "footer footer";
  }

}


@media (min-width: 768px) and (max-width: 992px) {

  .content {
    width: 68vw;
}

}




