.coming-soon-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10vw;

}

.coming-soon-wrapper {
    width: 80%;
    height: 20vh;
    justify-self: center;
    background: radial-gradient(closest-side, rgba(16, 12, 28, .9) 40%, rgba(255, 255, 255, 0.07) 90%);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    padding: 20px;
    box-shadow: -1px .5px 2px 1px rgba(5, 234, 255, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.coming-soon-text {
    position: relative;
    top: 20px;
    color: var(--aqua);
    font-size: 1em;
}


@media (max-width: 767px) {
    .coming-soon-container {
        margin-top: 10vh;
    }
}