@media (max-width: 767px) {

    .mobile-home-page-container {
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 5vw;
        padding: 5vh 0;
    }
    
    #dao-handshake-mobile {
        color: var(--yellow);
        display: flex;
        align-items: center;
        gap: 1em;
    }

    #dao-handshake-mobile > h3,
    #dao-handshake-mobile > .inline-emoji  {
        font-size: max(1.5em, 5vw);
    }
    
    .philosophy-container {
        width: 85vw;
        height: 85vw;
    }

    .philosophy-element {
        position: relative;
        width: 85vw;
        height: 85vw;
    }

    .contact-info-container-mobile {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }

    .contact-info-mobile {
        color: var(--yellow);
        display: flex;
        align-items: center;
        gap: 1em;
    }

    .contact-info-mobile > .emoji-bullet {
        font-size: 2em;
    }

    .contact-info-text {
        font-size: max(1.13em, 3vw);
        font-weight: 400;
    }


}

